import * as actionTypes from "../actions/actionTypes";
import { updateState } from "../utility";

const initialState = {
    samlEnabled: null,
    restrictLoginSSOOnly: null,
    byPassLoginPage: null,
    error: null,
    loading: false,
    currAssessment: null,
    containerConfig: null,
    config: null,
    isUpdating: false,
    blobData: null,
    loadingExport: false,
    containerAppSetting: null,
    activeUserCountStatusFetched: false,
    activeUserCount: null,
    showActiveUserCountNotification: null,
    activeUserCountStatusLoading: false,
    numberOfPersonsPutToInactive: null,
};

const getSamlEnabledStatusStart = (state) => {
    return updateState(state, { error: null, samlEnabled: null, loading: true });
};

const getSamlEnabledStatusSuccess = (state, action) => {
    return updateState(state, {
        samlEnabled: action.samlEnabled,
        restrictLoginSSOOnly: action.restrictLoginSSOOnly,
        byPassLoginPage: action.byPassLoginPage,
        error: null,
        loading: false,
    });
};

const getSamlEnabledStatusFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const clearSAMLEnabledStatus = (state) => {
    return updateState(state, {
        samlEnabled: null,
        restrictLoginSSOOnly: null,
        byPassLoginPage: null,
    });
};

const getCurrAssessmentStart = (state) => {
    return updateState(state, { error: null, currAssessment: null, loading: true });
};

const getCurrAssessmentSuccess = (state, action) => {
    return updateState(state, {
        currAssessment: action.currAssessment,
        error: null,
        loading: false,
    });
};

const getCurrAssessmentFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const clearCurrAssessment = (state) => {
    return updateState(state, { currAssessment: null });
};

const getContainerConfigStart = (state) => {
    return updateState(state, { error: null, containerConfig: null, loading: true });
};

const getContainerConfigSuccess = (state, action) => {
    return updateState(state, {
        containerConfig: action.containerConfig,
        error: null,
        loading: false,
    });
};

const getContainerConfigFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const getContainerConfigWithoutLoadingStart = (state) => {
    return updateState(state, { error: null });
};

const getContainerConfigWithoutLoadingSuccess = (state, action) => {
    return updateState(state, {
        containerConfig: action.containerConfig,
        error: null,
    });
};

const getContainerConfigWithoutLoadingFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        containerConfig: null,
    });
};

const getAdminConfigStart = (state) => {
    return updateState(state, { error: null, config: null, loading: true });
};

const getAdminConfigSuccess = (state, action) => {
    return updateState(state, {
        config: action.config,
        error: null,
        loading: false,
    });
};

const getAdminConfigFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateAdminConfigStart = (state) => {
    return updateState(state, { error: null, config: null, isUpdating: true });
};

const updateAdminConfigSuccess = (state, action) => {
    return updateState(state, {
        config: action.updatedConfig,
        containerConfig: action.updatedConfig,
        error: null,
        isUpdating: false,
    });
};

const updateAdminConfigFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const executeInactivationUsersLessLoginStart = (state) => {
    return updateState(state, { error: null, numberOfPersonsPutToInactive: null, isUpdating: true });
};

const executeInactivationUsersLessLoginSuccess = (state, action) => {
    return updateState(state, {
        error: null,
        isUpdating: false,
        numberOfPersonsPutToInactive: action.numberOfPersonsPutToInactive,
    });
};

const executeInactivationUsersLessLoginFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const downloadPowerBIModelStart = (state) => {
    return updateState(state, { error: null, loadingExport: true });
};

const downloadPowerBIModelSuccess = (state, action) => {
    return updateState(state, {
        blobData: action.blobData,
        error: null,
        loadingExport: false,
    });
};

const downloadPowerBIModelFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loadingExport: false,
    });
};

const getContainerAppSettingStart = (state) => {
    return updateState(state, { error: null, containerAppSetting: null, loading: true });
};

const getContainerAppSettingSuccess = (state, action) => {
    return updateState(state, {
        containerAppSetting: action.containerAppSetting,
        error: null,
        loading: false,
    });
};

const getContainerAppSettingFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        loading: false,
    });
};

const updateContainerAppSettingStart = (state) => {
    return updateState(state, { error: null, isUpdating: true });
};

const updateContainerAppSettingSuccess = (state, action) => {
    return updateState(state, {
        containerAppSetting: action.containerAppSetting,
        error: null,
        isUpdating: false,
    });
};

const updateContainerAppSettingFail = (state, action) => {
    return updateState(state, {
        error: action.error,
        isUpdating: false,
    });
};

const getActiveUserCountStatusStart = (state) => {
    return updateState(state, { error: null, activeUserCountStatusFetched: false, activeUserCount: null, showActiveUserCountNotification: null, activeUserCountStatusLoading: true });
};

const getActiveUserCountStatusSuccess = (state, action) => {
    return updateState(state, {
        activeUserCount: action.activeUserCount,
        showActiveUserCountNotification: action.showActiveUserCountNotification,
        activeUserCountStatusFetched: true,
        error: null,
        activeUserCountStatusLoading: false,
    });
};

const getActiveUserCountStatusFail = (state, action) => {
    return updateState(state, {
        activeUserCountStatusFetched: false,
        error: action.error,
        activeUserCountStatusLoading: false,
    });
};

const clearActiveUserCountStatusState = (state, action) => {
    return updateState(state, {
        activeUserCountStatusFetched: false,
        activeUserCount: null,
        showActiveUserCountNotification: null,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONFIG_SAML_ENABLED_STATUS_START:
            return getSamlEnabledStatusStart(state);
        case actionTypes.GET_CONFIG_SAML_ENABLED_STATUS_SUCCESS:
            return getSamlEnabledStatusSuccess(state, action);
        case actionTypes.GET_CONFIG_SAML_ENABLED_STATUS_FAIL:
            return getSamlEnabledStatusFail(state, action);

        case actionTypes.GET_CONFIG_CURR_ASSESSMENT_START:
            return getCurrAssessmentStart(state);
        case actionTypes.GET_CONFIG_CURR_ASSESSMENT_SUCCESS:
            return getCurrAssessmentSuccess(state, action);
        case actionTypes.GET_CONFIG_CURR_ASSESSMENT_FAIL:
            return getCurrAssessmentFail(state, action);

        case actionTypes.CLEAR_CONFIG_CURR_ASSESSMENT:
            return clearCurrAssessment(state);

        case actionTypes.GET_CONTAINER_CONFIG_START:
            return getContainerConfigStart(state);
        case actionTypes.GET_CONTAINER_CONFIG_SUCCESS:
            return getContainerConfigSuccess(state, action);
        case actionTypes.GET_CONTAINER_CONFIG_FAIL:
            return getContainerConfigFail(state, action);

        case actionTypes.GET_CONTAINER_CONFIG_WITHOUT_LOADING_START:
            return getContainerConfigWithoutLoadingStart(state);
        case actionTypes.GET_CONTAINER_CONFIG_WITHOUT_LOADING_SUCCESS:
            return getContainerConfigWithoutLoadingSuccess(state, action);
        case actionTypes.GET_CONTAINER_CONFIG_WITHOUT_LOADING_FAIL:
            return getContainerConfigWithoutLoadingFail(state, action);

        case actionTypes.GET_ADMIN_CONFIG_START:
            return getAdminConfigStart(state);
        case actionTypes.GET_ADMIN_CONFIG_SUCCESS:
            return getAdminConfigSuccess(state, action);
        case actionTypes.GET_ADMIN_CONFIG_FAIL:
            return getAdminConfigFail(state, action);

        case actionTypes.UPDATE_ADMIN_CONFIG_START:
            return updateAdminConfigStart(state);
        case actionTypes.UPDATE_ADMIN_CONFIG_SUCCESS:
            return updateAdminConfigSuccess(state, action);
        case actionTypes.UPDATE_ADMIN_CONFIG_FAIL:
            return updateAdminConfigFail(state, action);

        case actionTypes.EXECUTE_INACTIVATION_USERS_LESS_LOGIN_START:
            return executeInactivationUsersLessLoginStart(state);
        case actionTypes.EXECUTE_INACTIVATION_USERS_LESS_LOGIN_SUCCESS:
            return executeInactivationUsersLessLoginSuccess(state, action);
        case actionTypes.EXECUTE_INACTIVATION_USERS_LESS_LOGIN_FAIL:
            return executeInactivationUsersLessLoginFail(state, action);

        case actionTypes.DOWNLOAD_POWERBIMODEL_START:
            return downloadPowerBIModelStart(state);
        case actionTypes.DOWNLOAD_POWERBIMODEL_SUCCESS:
            return downloadPowerBIModelSuccess(state, action);
        case actionTypes.DOWNLOAD_POWERBIMODEL_FAIL:
            return downloadPowerBIModelFail(state, action);

        case actionTypes.GET_CONTAINER_APP_SETTING_START:
            return getContainerAppSettingStart(state);
        case actionTypes.GET_CONTAINER_APP_SETTING_SUCCESS:
            return getContainerAppSettingSuccess(state, action);
        case actionTypes.GET_CONTAINER_APP_SETTING_FAIL:
            return getContainerAppSettingFail(state, action);

        case actionTypes.UPDATE_CONTAINER_APP_SETTING_START:
            return updateContainerAppSettingStart(state);
        case actionTypes.UPDATE_CONTAINER_APP_SETTING_SUCCESS:
            return updateContainerAppSettingSuccess(state, action);
        case actionTypes.UPDATE_CONTAINER_APP_SETTING_FAIL:
            return updateContainerAppSettingFail(state, action);

        case actionTypes.GET_ACTIVE_USER_COUNT_STATUS_START:
            return getActiveUserCountStatusStart(state);
        case actionTypes.GET_ACTIVE_USER_COUNT_STATUS_SUCCESS:
            return getActiveUserCountStatusSuccess(state, action);
        case actionTypes.GET_ACTIVE_USER_COUNT_STATUS_FAIL:
            return getActiveUserCountStatusFail(state, action);
        case actionTypes.CLEAR_ACTIVE_USER_COUNT_STATUS:
            return clearActiveUserCountStatusState(state, action);
        case actionTypes.CLEAR_SAML_ENABLED_STATUS:
            return clearSAMLEnabledStatus(state);

        default:
            return state;
    }
};

export default reducer;
